<template>
  <div style="margin: -15px">
    <a-spin :spinning="spinning">
      <div class="header">
        <div style="display:flex;align-items: center;">
          <div>姓名：</div><a-input v-model:value="text" placeholder="请输入" style="width: 180px" />
          <!--        <a-button style="margin-left: 20px" type="primary" @click="search">查询</a-button>-->
          <div style="margin-left: 50px">
            状态：
            <a-radio-group v-model="type" @change="onChange">
              <a-radio-button value="专家">
                专家
              </a-radio-button>
              <a-radio-button value="非专家">
                非专家
              </a-radio-button>
            </a-radio-group>
          </div>
        </div>
        <div>
          <a-button type="primary" @click="search">查询</a-button>
          <a-button @click="reset" style="margin-left: 10px">重置</a-button>
        </div>
      </div>
      <div style="border-bottom: 12px solid #F0F2F5">
      </div>
      <a-button style="float:right;margin: 10px;z-index: 1000" @click="exportFunc"><a-icon style="color: #00A854;" type="file-excel" />批量导出</a-button>
      <a-table @change="handleTableChange" :pagination="false" style="margin-top: 10px" :columns="columns" :data-source="tableData">
        <template slot="number" slot-scope="text, row, index">
          <a @click="fsOpen(row.memberId, row.expertId, row.name)">{{row.number}}</a>
        </template>
      </a-table>
      <div style="margin-top: 30px;padding-left: 20px">
            <span style="font-size: 17px;color: #929292">共 {{ pagination.total }} 条记录 第 {{ pagination.pageNum }} / {{
                Math.ceil(pagination.total / pagination.pageSize)
              }} 页</span>
        <a-pagination
            v-model="pagination.pageNum"
            :page-size="pagination.pageSize"
            :total="pagination.total"
            show-quick-jumper
            show-size-changer
            style="float: right;margin-bottom: 40px"
            @change="pageChange"
            @showSizeChange="pageChange"
        >
        </a-pagination>
      </div>
    </a-spin>

    <a-drawer           placement="right"
                        :visible="editVisible"
                        :width="1100" title="访问量" @close="editClose">
      <b>{{edName}}个人主页访问列表</b>
      <a-button @click="exportFunc($event, true)" style="float:right;margin: 10px"><a-icon style="color: #00A854;" type="file-excel" />批量导出</a-button>

      <a-table :pagination="false" style="margin-top: 10px" :loading="fsLoading" :columns="fsColumns" :data-source="fsTableData">

      </a-table>
      <div style="margin-top: 30px;padding-left: 20px">
            <span style="font-size: 17px;color: #929292">共 {{ fsPagination.total }} 条记录 第 {{ fsPagination.pageNum }} / {{
                Math.ceil(fsPagination.total / fsPagination.pageSize)
              }} 页</span>
        <a-pagination
            v-model="fsPagination.pageNum"
            :page-size="fsPagination.pageSize"
            :total="fsPagination.total"
            show-quick-jumper
            show-size-changer
            style="float: right;margin-bottom: 40px"
            @change="fsPageChange"
            @showSizeChange="fsPageChange"
        >
        </a-pagination>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import {
  getFangWenExportApi,
  getVisitsByExpertApi,
  getVisitsByNoApi,
  getVisitsByNoPeopleApi
} from "@/service/zoomMeet_api";
import api_header_config from "@/service/api_header_config";

export default {
  name: "Visits",
  data () {
    return {
      edName: '',
      fsTableData: [],
      fsColumns: [
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '医院',
          dataIndex: 'hospital'
        },
        {
          title: '科室',
          dataIndex: 'offices'
        },
        {
          title: '职称',
          dataIndex: 'title'
        },
        {
          title: '平台专家',
          dataIndex: 'isPlatformExpert'
        },
        {
          title: '认证状态',
          dataIndex: 'certStatus'
        },
        {
          title: '访问时间',
          dataIndex: 'createdTime'
        }
      ],
      fsLoading: false,
      editVisible: false,
      pagination: {   //存储大table的分页
        total: 0,
        pageSize: 10,
        pageNum: 1,
      },
      fsPagination: {   //存储大table的分页
        total: 0,
        pageSize: 10,
        pageNum: 1,
      },
      tableData: [],
      columns: [
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '医院/单位',
          dataIndex: 'hospital'
        },
        {
          title: '科室',
          dataIndex: 'offices'
        },
        {
          title: '职称',
          dataIndex: 'title'
        },
        {
          title: '平台专家',
          dataIndex: 'isPlatformExpert'
        },
        {
          title: '认证状态',
          dataIndex: 'certStatus'
        },
        {
          title: '访问量',
          dataIndex: 'number',
          scopedSlots: { customRender: 'number' },
          sorter: true,
        },
        {
          title: '注册时间',
          dataIndex: 'createdTime'
        }
      ],
      spinning: false,
      text: '',
      type: '专家',
      sortIndex: 'desc',
      expertId: null,
      memberId: null,
      header: api_header_config.api_header
    }//dataReturn
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    async exportFunc (event,type2) {
      if (type2) {
        if (this.memberId) {
          window.open(this.header + '/v3/social/records/visitMemberList/export?memberId=' + this.memberId, '_self')
        } else if (this.expertId) {
          window.open(this.header + '/v3/social/records/visitMemberList/export?expertId=' + this.expertId, '_self')
        }
        return
      }
      if (this.type === '专家') {
        window.open(this.header + '/v3/social/records/visitsByExpert/export?name=' + this.text + '&sortIndex=' + this.sortIndex, '_self')
      } else if (this.type === '非专家') {
        window.open(this.header + '/v3/social/records/visitsByMember/export?name=' + this.text + '&sortIndex=' + this.sortIndex, '_self')
      }
    },
    async handleTableChange (a,b,c) {
      if (c.order === 'descend' || !c.order) this.sortIndex = 'desc'
      else this.sortIndex = 'asc'
      await this.getTableData()
    },
    async getTableData () {
      this.spinning = true
      const data = {
        name: this.text,
        page: this.pagination.pageNum,
        pageSize: this.pagination.pageSize,
        sortIndex: this.sortIndex
      }
      if (this.type === '专家') {
        const res = await getVisitsByExpertApi(data)
        if (res.code === 200) {
          this.tableData = res.data
          this.spinning = false
          this.pagination.total = res.count
        }
      } else if (this.type === '非专家') {
        const res = await getVisitsByNoApi(data)
        if (res.code === 200) {
          this.tableData = res.data
          this.spinning = false
          this.pagination.total = res.count
        }
      }


    },
    async fsOpen (memberId, expertId, edName) {
      this.pagination.pageNum = 1
      this.edName = edName
      this.spinning = true
      this.memberId = memberId
      this.expertId = expertId
      await this.getFsTableData()
      this.editVisible = true
      this.spinning = false
    },
    async getFsTableData () {
      const data = {
        memberId: this.memberId,
        expertId: this.expertId,
        page: this.fsPagination.pageNum,
        pageSize: this.fsPagination.pageSize
      }
      const res = await getVisitsByNoPeopleApi(data)
      if (res.code === 200) {
        this.fsTableData = res.data
        this.fsPagination.total = res.count
      }
    },
    editClose () {
      this.editVisible = false
    },
    pageChange(page_no, page_size) {
      this.pagination.pageNum = page_no
      this.pagination.pageSize = page_size
      this.getTableData()
    },
    fsPageChange(page_no, page_size) {
      this.fsPagination.pageNum = page_no
      this.fsPagination.pageSize = page_size
      this.getFsTableData()
    },
    reset () {
      this.text = ''
      this.type = '专家'
      this.pagination.pageNum = 1
      this.pagination.pageSize = 10
      this.sortIndex = 'desc'
      this.getTableData()
    },
    search () {
      this.pagination.pageNum = 1
      this.sortIndex = 'desc'
      this.getTableData()
    },
    onChange (e) {
      this.type = e.target.value
      this.pagination.pageNum = 1
      this.getTableData()
    }
  }
}
</script>

<style scoped>
.header {
  padding: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*border-bottom: 10px solid #F0F2F5;*/
}
</style>